<template>
  <div class="mini-cart__active-item">
    <div class="mini-cart__active-item-image">
      <img :src="thumbIng" alt="item.description" width="80" height="80" />
    </div>
    <div class="stacked-left-spaced">
      <a :href="href" class="mini-cart__active-item-link">
        <div class="mini-cart__active-item-name">{{ item.description }}</div>
      </a>
      <div v-if="item.single_use_plastic" class="mini-cart__active-item-sup">
        <i class="fa-solid fa-triangle-exclamation"></i>
        Single use plastic item
      </div>
      <div class="mini-cart__active-item-price" :class="priceClass">{{ item.price }}</div>
    </div>
    <div class="stacked-right-spaced grow">
      <div class="mini-cart__quantity">
        <updated-tip :message="errorMessage" tipType="error" v-if="errorMessage" />
        <div class="itemcard__qty-ctrl">
          <button type="button"
            class="itemcard__qty_less"
            @click="decrementQuantity"
            :disabled="disableDecrement">
            <i class="fa-solid fa-minus"></i>
          </button>
          <input name="k2c_addcheckeditems_qty_$item.prodcode"
            type="number"
            :value="item.quantity"
            @change="updateQuantity"
            size="6"
            maxlength="6"
            class="qty_text"
            :min="item.min_order_qty"
          />
          <button type="button"
            class="itemcard__qty_more"
            @click="incrementQuantity"
            :disabled="disableIncrement"
          >
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="mini-cart__active-item-remove">
        <button type="button"
          class="wc-button wc-button--subdued wc-button--small"
          @click="deleteItem(item.itemid)">
          <span class="label">
            Remove
          </span>
        </button>
      </div>
    </div>
    <div class="mini-cart__deleting" v-if="deleting"></div>
  </div>
</template>

<script>
import UpdatedTip from '@/components/common/UpdatedTip/index';

export default {
  name: 'MiniItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    updateItem: {
      type: Function,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
    itemsUpdating: {
      type: Array,
      required: true,
    },
    itemsDeleting: {
      type: Array,
      required: true,
    },
    updateFailed: {
      type: Boolean,
      required: true,
    },
    deleteFailed: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    'updated-tip': UpdatedTip,
  },
  computed: {
    disableIncrement() {
      return this.item.quantity >= 999999;
    },
    disableDecrement() {
      return this.item.quantity <= this.item.min_order_qty;
    },
    priceClass() {
      return {
        'mini-cart__updating': this.itemsUpdating.includes(this.item.itemid),
      };
    },
    deleting() {
      return this.itemsDeleting.includes(this.item.itemid);
    },
    thumbIng() {
      return this.item?.primary_image?.thumb || '/media/images/no-image-image.svg';
    },
    errorMessage() {
      if (this.updateFailed.includes(this.item.itemid)) {
        return 'Failed to update quantity';
      }
      if (this.deleteFailed.includes(this.item.itemid)) {
        return 'Failed to remove item';
      }
      return '';
    },
    href() {
      return `${this.item.uri}?feature=mini_cart`;
    },
  },
  methods: {
    updateQuantity(e) {
      const qty = parseInt(e.target.value, 10);
      if (Number.isNaN(qty)) {
        e.target.value = this.item.quantity;
        return;
      }
      if (qty < this.item.min_order_qty) {
        e.target.value = this.item.min_order_qty;
        return;
      }
      if (qty > 999999) {
        e.target.value = 999999;
        return;
      }
      this.updateItem(this.item.itemid, qty);
    },
    incrementQuantity() {
      const qty = this.item.quantity + 1;
      this.updateQuantity({ target: { value: qty } });
    },
    decrementQuantity() {
      const qty = this.item.quantity - 1;
      this.updateQuantity({ target: { value: qty } });
    },
    deleteItem() {
      this.removeItem(this.item.itemid);
    },
  },
};
</script>
